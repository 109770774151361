@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgba(55, 65, 81, var(--tw-bg-opacity));
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(75, 85, 99, var(--tw-bg-opacity));
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba(107, 114, 128, var(--tw-bg-opacity));
}